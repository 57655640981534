import { NgModule } from '@angular/core';
import {FileListComponent} from './file-list.component';
import {CommonModule} from '@angular/common';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [FileListComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    MatIconModule,
    MatTableModule,
    MatMenuModule,
    MatButtonModule,
  ],
  exports: [FileListComponent],
  providers: []
})
export class FileListModule { }

