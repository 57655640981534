import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'form-error',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {
  _text: string;
  _hide = true;
  _labelElement;
  _popOverError = false;

  @Input() set text(value) {
    if (value !== this._text) {
      this._text = value;
      this._hide = !value;
      this.cdr.detectChanges();
    }
  }

  @Input() set labelElement(value) {
    this._labelElement = value;
    this.cdr.detectChanges();
  }

  @Input() set labelElementClass(value) {
    if(!this._hide && this._labelElement[0]) {
      this._labelElement[0].classList.add('errorColor');
    } else if(this._labelElement[0]) {
      this._labelElement[0].classList.remove('errorColor');
    }
    this.cdr.detectChanges();
  }

  set popOverError(value: boolean) {
    const shouldShowPopover = this.elRef.nativeElement.parentElement.classList.contains('textBoxItemWrapper');
    if(shouldShowPopover) {
      this._popOverError = true;
    } else {
      this._popOverError = value;
    }
  }

  get popOverError(): boolean {
    return this._popOverError;
  }

  constructor(private cdr: ChangeDetectorRef, private elRef: ElementRef) { }

  ngOnInit(): void {}
}
