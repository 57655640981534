import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';
moment.locale('nl');
import {
  CreateStudyResult, MultipleStudyResults, PaginatedResult, StudyComponentType,
  StudyResult, StudyResultType,
  UpdateStudyResult
} from '../../../models';
import {environment} from '../../../../../environments/environment';
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";

@Injectable({
  providedIn: 'root'
})
export class StudyResultService {

  constructor(private http: HttpClient) {

  }

  getAll(pageIndex = 1, pageSize = 10,
         clientId?: undefined | number,
         clientStudyTrackId?: undefined | number,
         type?: undefined | StudyComponentType,
         studyModuleId?: undefined | number,
         search?: undefined | string,
  ): Observable<PaginatedResult<StudyResult>> {

    const filter = {};

    if (clientId) {
      filter['clientId'] = clientId
    }

    if (clientStudyTrackId) {
      filter['clientStudyTrackId'] = clientStudyTrackId;
    }

    if (type) {
      filter['type'] = type;
    }

    if (studyModuleId) {
      filter['studyModuleId'] = studyModuleId;
    }

    if (search) {
      filter['search'] = search;
    }

    return this.http.get<PaginatedResult<StudyResult>>(`${environment.apiUrl}/study-result?page=${pageIndex}&limit=${pageSize}&filter=${JSON.stringify(filter)}`).pipe((map(response => {
      response.items = response.items.map(plain => plainToClass(StudyResult, plain));
      return response;
    })));
  }

  get(id: number): Observable<StudyResult> {
    return this.http.get<StudyResult>(`${environment.apiUrl}/study-result/${id}`);
  }

  create(dto: CreateStudyResult): Observable<StudyResult> {
    return this.http.post<StudyResult>(`${environment.apiUrl}/study-result`, dto);
  }

  createBatched(dto: MultipleStudyResults): Observable<MultipleStudyResults> {
    return this.http.post<MultipleStudyResults>(`${environment.apiUrl}/study-result/batch`, dto);
  }

  update(id: number, dto: UpdateStudyResult): Observable<StudyResult> {
    return this.http.patch<StudyResult>(`${environment.apiUrl}/study-result/${id}`, dto);
  }

  delete(id: number): any {
    return this.http.delete(`${environment.apiUrl}/study-result/${id}`);
  }

  getAllModules(studyProfileId: number): any {
    return this.http.get(`${environment.apiUrl}/student/my/track/0/modules?id=${studyProfileId}`);
  }
}
