import {InjectionToken} from '@angular/core';
import {patternToError} from '../../../util/validators/validatorHelper';

export const defaultErrors = {
  required: (error) => `Dit veld is verplicht.`,
  minlength: ({ requiredLength, actualLength }) => `Minimaal ${requiredLength}, maar er zijn er ${actualLength} ingevuld!`,
  maxlength: ({ requiredLength, actualLength }) => `Maximaal ${requiredLength}, maar er zijn er ${actualLength} ingevuld!`,
  pattern: (error) => patternToError(error.requiredPattern),
  iban: (error) => 'Incorrecte IBAN. Voorbeeld: NL05 RABO 1234 1234 00',
  cocNumberInvalid: (error) => 'Dit KVK nummer is ongeldig. Het moet 8 cijfers bevatten.',
  vatNumberInvalid: (error) => 'Dit NTW nummer is ongeldig. Voorbeeld: NL123456789B01.',
  bsn11: (error) => 'Dit BSN-nummer is niet geldig',
  requireCheckboxesToBeChecked: (error) => 'Er moet minimaal één optie aangevinkt zijn.',
  hasToBeTrue: (error) => 'Dit veld is verplicht.',
  noMinOneSelected: (error) => 'Er moet minimaal één optie aangevinkt zijn.',
  onlyLetters: (error) => 'Dit veld mag alleen letters bevatten.',
  incorrectInitials: (error) => 'Dit veld mag alleen hoofdletters en punten bevatten.',
  incorrectEmail: (error) => 'Dit is geen valide email adres. Voorbeeld: jan@alleman.nl',
  incorrectPhone: (error) => 'Dit is geen valide telefoonnummer. Voorbeeld: 0201234567 of 0612345678',
  totalPointsError: (error) => 'Het aantal te behalen punten is niet gelijk aan het aantal punten dat behaald moet worden.',
  minLengthArray: (error) => 'Het aantal ... is niet voldoende.',
  singleOptionNeedsToBeTrue: (error) => 'Een van de antwoorden is niet ingesteld als correct.',
};

export const patternErrors = {
  // pattern(['"^[a-z0-9_-]{8,15}$"; ']): (error) => 'Veld mag alleen letters bevatten',
  // pattern: ({lettersOnlyPattern}) => 'Veld mag alleen letters bevatten',
};

export const serverErrors = {

};

export const PATTERN_ERRORS = new InjectionToken('PATTERN_ERRORS', {
  providedIn: 'root',
  factory: () => patternErrors
});

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});


