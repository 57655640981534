import {BrowserModule} from '@angular/platform-browser';
import {InjectionToken, NgModule, Provider} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoaderService} from './core/services/loader/loader.service';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PaginatorNL_nld} from './util/paginator/paginator-nl';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MomentModule} from 'ngx-moment';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {
  HandedInDocumentsDialogComponent
} from './modules/shared/handed-in-documents-dialog/handed-in-documents-dialog.component';
import {MatCardModule} from "@angular/material/card";
import {FileListModule} from "./modules/shared/file-list/file-list.module";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {DropdownModule} from "./modules/shared/dropdown/dropdown.module";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import {
  IMsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import {environment} from "../environments/environment";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import {MatFileUploadModule} from '@martyganz/mat-file-upload';
import {FinishFormButtonModule} from './modules/shared/finish-form-button/finish-form-button.module';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MockMsalService} from "./core/services/mock/mock-msal.service";
import {BaseDialogModule} from "./modules/shared/dialogs/base-dialog/base-dialog.module";
registerLocaleData(localeNl);

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authClientId,
      authority: environment.authAuthority,
      redirectUri: environment.authRedirectUri,
      postLogoutRedirectUri: environment.authLogoutRedirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback: console.log,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl + '/*', [environment.authApiScope]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        environment.authApiScope
      ]
    },
    //loginFailedRoute: '/' // Don't have one for this
  };
}

const providers: Provider[] = [{
  provide: MsalService,
  useClass: environment.skipAuthentication ? MockMsalService : MsalService
}];

const imports = [];
const bootstrap = [];

if (!environment.skipAuthentication) {
  providers.push({
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }, {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalGuard,
    MsalBroadcastService
  );

  imports.push(MsalModule);
  bootstrap.push(MsalRedirectComponent);
}

@NgModule({
  declarations: [
    AppComponent,
    HandedInDocumentsDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MomentModule,
    ScrollingModule,
    MatIconModule,
    MatFormFieldModule,
    MatCardModule,
    FileListModule,
    MatButtonModule,
    MatDialogModule,
    DropdownModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    NgChartsModule,
    MatFileUploadModule,
    FinishFormButtonModule,
    MatSortModule,
    MatTableModule,
    FormsModule,
    MatProgressSpinnerModule,
    BaseDialogModule,
    ...imports
  ],
  providers: [
    LoaderService,
    {
      provide: MatPaginatorIntl,
      useValue: PaginatorNL_nld()
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'nl-NL'
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true, hasBackdrop: true }
    },
    ...providers,
  ],
  bootstrap: [
    AppComponent,
    ...bootstrap
  ]
})
export class AppModule {
}
