export const environment = {
  production: true,
  skipAuthentication: false,
  apiUrl: 'https://api-meditouch.vqtech.nl/v1',
  authClientId: 'xxxx',
  authAuthority: 'xxxx',
  authRedirectUri: 'https://meditouch.vqtech.nl/',
  authLogoutRedirectUri: 'https://meditouch.vqtech.nl/',
  authApiScope: 'xxxx',
  appEnv: 'development'
};
