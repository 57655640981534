import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import {
  DocUpload,
  DropdownData,
  RemoteDocType,
} from "../../../../../core/models";
import {RemoteDocumentService} from "../../../../../core/services/medivus/remote-document/remote-document.service";

moment.locale('nl');

export interface AllowedFileUpload {
  dropdown: DropdownData[];
  extensions: string;
}

@Component({
  selector: 'app-upload-document-dialog',
  templateUrl: './upload-document-dialog.component.html',
  styleUrls: ['./upload-document-dialog.component.scss'],
  providers: [MatDatepickerModule]
})
export class UploadDocumentDialogComponent implements OnInit {
  visible = true;
  breakpoint = 520;
  public uploadFormGroup: FormGroup;
  private docToUpload = null;

  get prettyExtensions(): string {
    return 'Toegestaan: ' + this.data.extensions.replace(/,/g, ', ') + '.';
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AllowedFileUpload,
              public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
              public formBuilder: FormBuilder,
              private ngZone: NgZone,
              public remoteDocumentService: RemoteDocumentService) {
    this.initForm();
  }

  initForm(): void {
    this.uploadFormGroup = this.formBuilder.group({
      document: [null, [Validators.required]],
      type: [!this.data.dropdown || this.data.dropdown?.length === 0
        ? RemoteDocType.Text : this.data.dropdown?.length === 1
          ? this.data.dropdown[0].value : null,
        [Validators.required]]
    });
  }

  ngOnInit(): void {
    const w = window.innerWidth;
    if (w >= this.breakpoint) {
      this.visible = true;
    } else {
      // whenever the window is less than 520, hide this component.
      this.visible = false;
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    if(this.uploadFormGroup.valid) {
      this.docToUpload.type = this.uploadFormGroup.get('type').value;
      this.dialogRef.close(this.docToUpload);
    }
  }

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  selectedFilesChanged(event): void {
    if(!event) {
      this.uploadFormGroup.patchValue({
        document: null,
        type: null
      });
    } else if(event.length === 1) {
      const file = event[0];
      this.uploadFormGroup.get('document').setValue(file);
      const doc = new DocUpload();
      doc.file = file;
      doc.name = file.name;
      this.docToUpload = doc;
    }
  }
}
