<app-base-dialog
  [title]="data.title ? data.title : 'Weet je het zeker?'"
>
  <p *ngIf="data.message">{{data.message}}</p>
  <p *ngIf="!data.message">{{data}}</p>
  <span *ngIf="data.warningText" class="warning-message">{{data.warningText}}</span>

  <ng-container dialog-actions>
    <button mat-button class="button-Kill btn addCard-button" (click)="close()">{{data.cancelText}}</button>
    <button
      [disabled]="data.warningText"
      mat-raised-button
      class="button-Add btn addCard-button"
      style="background-color: {{color}}"
      [color]="data.confirmText === 'Verwijderen' ? 'warn' : 'primary'"
      (click)="confirm()"
    >
      {{data.confirmText}}
    </button>
  </ng-container>
</app-base-dialog>
