import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ThemeService} from './core/services/theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(private themeService: ThemeService) {
  }

  public isDarkTheme: Observable<boolean>;

  ngOnInit(): void {
    this.isDarkTheme = this.themeService.isDarkTheme;
    this.themeService.setDarkTheme(false); // TODO: Retrieve this from local storage or the API, etc.
  }
}
