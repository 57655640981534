<mat-form-field appearance="fill">
  <mat-label>{{label}}</mat-label>
  <mat-select [formControl]="control" #singleSelect [disabled]="disabled">
    <mat-option>
      <ngx-mat-select-search [formControl]="filterControl" placeholderLabel="Zoeken..."
                             noEntriesFoundLabel="Niets gevonden"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="nullable" (click)="selectedOption(null)" [value]="null"></mat-option>
    <mat-option (click)="selectedOption(data.id)"
                *ngFor="let data of filteredData | async" [value]="data.id">
      {{data.display ? data.display : data.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
