import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmationDialogComponent} from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import {RemoteDocumentService} from "../../../core/services/medivus/remote-document/remote-document.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RemoteDocProtocol, RemoteDocument} from "../../../core/models";
import {enumValueToName, RemoteDocTypeDropdown} from "../../../util/enum/enum-render-helper";
import {UploadDocumentDialogComponent} from '../../overviews/clients/dialogs/upload-document-dialog/upload-document-dialog.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})
export class FileListComponent implements OnInit {
  @Input() files: RemoteDocument[] = [];
  @Input() entity: string;
  @Input() entityId: number;
  @Input() externalStorageId: string;
  @Input() externalStorageType: string;
  @Input() withScroll = true;
  @Output() filesChanged: EventEmitter<any> = new EventEmitter();

  public remoteDocTypeDropdown = RemoteDocTypeDropdown;
  public enumDisplayHelper = enumValueToName;
  public uploading = false;

  displayedColumns: string[] = ['fileName', 'fileType', 'uploadDate', 'menu'];

  constructor(private dialog: MatDialog,
              public remoteDocumentService: RemoteDocumentService,
              private backendSnackbar: MatSnackBar) {
  }

  ngOnInit(): void {}

  downloadAndViewFile(event): void {
    this.remoteDocumentService.downloadFile(event).subscribe(res => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,width=800,height=600');
    });
  }

  deleteFile(event): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Je staat op het punt om dit bestand te verwijderen.`,
        deletable: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.remoteDocumentService.delete(event).subscribe(res => {
          if(res) {
            this.backendSnackbar.open(`Bestand verwijderd!`, 'Sluiten', {
              duration: 1500
            });

            this.filesChanged.next(true);
          }
        });
      }
    });
  }

  // TODO: Needs a default URL?
  async openRemoteMap(file: RemoteDocument) {
    if (!file) {
      if (this.externalStorageType === RemoteDocProtocol.GoogleDrive) {
        window.open(`https://drive.google.com/drive/folders/${this.externalStorageId}`, 'drive');
      } else if (this.externalStorageType === RemoteDocProtocol.OneDrive) {
        window.open(await this.remoteDocumentService.getAccessLink(this.externalStorageId).toPromise(), 'drive');
      }
    } else {
      if (file.protocol === RemoteDocProtocol.GoogleDrive) {
        window.open(`https://drive.google.com/drive/folders/${file.uri}`,
          'drive');
      } else if (file.protocol === RemoteDocProtocol.OneDrive) {
        window.open(await this.remoteDocumentService.getAccessLink(file.uri).toPromise(),
          'drive');
      } else {
        this.backendSnackbar.open('Geen Drive map gevonden, probeer de pagina te vernieuwen, check het handmatig en geef het door!', 'Sluiten', {
          duration: 5000
        });
      }
    }
  }

  uploadDocument(): void {
    const dialogRef = this.dialog.open(UploadDocumentDialogComponent, {
      disableClose: true,
      data: {
        dropdown: RemoteDocTypeDropdown,
        extensions: '.png,.jpg,.jpeg,.pdf,.docx,.zip'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.uploading = true;
        this.remoteDocumentService.uploadToEntity(this.entity, this.entityId, result).subscribe(res => {
          if (res) {
            this.uploading = false;
            this.backendSnackbar.open(`Document toegevoegd`, 'Sluiten', {
              duration: 1500
            });

            // this.getAttachments();
            this.filesChanged.emit(result);
          }
        }, e => {
          this.uploading = false;
        });

      } else {
        console.error('Error while saving Document!');
      }
    });
  }
}
