<app-base-dialog title="Opdracht beoordelen">
  <div class="dialog-content-container" *ngIf="submission">
    <app-file-list
      (filesChanged)="getSubmission()"
      [files]="submission.attachments"
    ></app-file-list>

    <!--      <div class="buttonIndialogBundler">-->
    <form [formGroup]="resultsFormGroup">
      <div class="inputBundler mixInputs">
        <mat-form-field appearance="fill">
          <mat-label>Beoordeling</mat-label>
          <input matInput formControlName="result">
        </mat-form-field>

        <mat-form-field appearance="fill" class="bigInput">
          <mat-label>Toelichting</mat-label>
          <textarea matInput formControlName="remarks"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3"></textarea>
        </mat-form-field>
      </div>
    </form>
    <!--      </div>-->
  </div>

  <div dialog-actions>
    <button mat-button (click)="close()">Annuleren</button>
    <button mat-button [disabled]="resultsFormGroup.invalid" (click)="save()" class="saveButton">Opslaan</button>
  </div>
</app-base-dialog>
