import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinishFormButtonComponent } from './finish-form-button.component';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    FinishFormButtonComponent
  ],
  exports: [
    FinishFormButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class FinishFormButtonModule { }
