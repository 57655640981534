import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss']
})
export class BaseDialogComponent implements OnInit {
  @Input() title: string;
  @Input() noContentPadding = false;
  @Input() noMaxHeight = false;
  @Output() closeAction: EventEmitter<any> = null;

  constructor(
    public dialogRef: MatDialogRef<BaseDialogComponent>,
  ) { }

  ngOnInit(): void {
  }
}
