<app-base-dialog
  [title]="'Document uploaden'"
>
  <form [formGroup]="uploadFormGroup" (ngSubmit)="save()">
    <div class="dialog-content-container uploadDocumentDialog">
      <mat-file-upload
        [labelText]="prettyExtensions"
        [selectButtonText]="'Kies bestand'"
        [uploadButtonText]="'Uploaden'"
        [allowMultipleFiles]="false"
        [showUploadButton]="false"
        (selectedFilesChanged)="selectedFilesChanged($event)"
        [acceptedTypes]="data.extensions"
      >
      </mat-file-upload>

      <mat-form-field *ngIf="data.dropdown && data.dropdown.length > 0">
        <mat-label>Type bestand</mat-label>
        <mat-select formControlName="type" required>
          <mat-option *ngFor="let type of data.dropdown" [value]="type.value">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <button #hiddenSaveButton [hidden]="true"></button>
  </form>

  <ng-container dialog-actions>
    <app-form-dev-tools [formGroup]="uploadFormGroup"></app-form-dev-tools>
    <app-finish-form-button
      [hiddenButton]="hiddenSaveButton"
    ></app-finish-form-button>
  </ng-container>
</app-base-dialog>
