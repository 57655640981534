import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as moment from 'moment';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {FormControl} from '@angular/forms';
import {takeUntil} from "rxjs/operators";
import {FilterResponse} from "../filter/filter.component";

moment.locale('nl');

export class SearchableDropdownData {
  id: any;
  display: string;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges, OnDestroy {
  @Input() data: SearchableDropdownData[];
  @Input() label: string;
  @Input() control: FormControl;
  @Input() idName: string;
  @Input() displayName: string;
  @Input() disabled: boolean;
  @Input() nullable = true;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() optionSelected: EventEmitter<FilterResponse> = new EventEmitter();

  singleSelect: MatSelect;
  filterControl: FormControl;
  filteredData: ReplaySubject<any[]>;

  @ViewChild('singleSelect') set content(content: MatSelect) {
    if (content != null) {
      this.singleSelect = content;
    }
  }

  public selectedDates = [null, null, null];
  protected onDestroySub = new Subject<void>();

  constructor() {
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterControl = new FormControl();
    this.filteredData = new ReplaySubject<any[]>(1);

    this.filterControl.valueChanges.pipe(takeUntil(this.onDestroySub)).subscribe(() => {
      let search = this.filterControl.value;
      if (!search) {
        this.filteredData.next(this.data);
        return;
      } else {
        search = search.toLowerCase();
      }

      this.filteredData.next(this.data.filter(data => data.display.toLowerCase().indexOf(search) > -1));
    });

    this.filteredData.next(this.data);
  }

  selectedOption(value): void {
    this.optionSelected.next(value);
  }
}
