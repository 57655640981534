import {NgModule} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {DropdownComponent} from "./dropdown.component";
import {FormErrorModule} from '../../../core/directives/form-error-directive/form-error.module';

@NgModule({
  declarations: [
    DropdownComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    FormErrorModule
  ],
  exports: [
    DropdownComponent
  ]
})
export class DropdownModule {
}
