import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewContainerRef
} from '@angular/core';
import {ControlContainer, FormArray, FormControl, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {FORM_ERRORS, PATTERN_ERRORS} from './form-errors';
import {FormErrorContainerDirective} from './form-error-container.directive';
import {FormErrorSubmitDirective} from './form-error-submit.directive';
import {EMPTY, merge, Observable} from 'rxjs';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {ErrorStateMatcher} from "@angular/material/core";
import {FormErrorComponent} from "../../../modules/shared/form-error/form-error.component";

@Directive({
  selector: '[formControlName], [formControl], [formArrayName]'
})
export class FormErrorHandlingDirective implements OnInit, OnDestroy {
  ref: ComponentRef<FormErrorComponent>;
  submit$: Observable<Event>;
  container: ViewContainerRef;
  @Input() serverError = {};

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() private formDir: NgControl,
    @Optional() private formGroupDirective: ControlContainer,
    private elementRef: ElementRef,
    @Optional() formErrorContainer: FormErrorContainerDirective,
    // so we removed the @Host() decorator here to support dynamic forms (Form element is outside of the formControl component's scope).
    // this might cause issues down the line, as I understand it will now just go up the hierachy until it finds a Form element.
    // read https://indepth.dev/posts/1063/a-curious-case-of-the-host-decorator-and-element-injectors-in-angular for a nice in depth view of @Host().
    // TODO: this might be a solution? https://medium.com/@a.yurich.zuev/angular-nested-template-driven-form-4a3de2042475
    @Optional() /*@Host()*/ private form: FormErrorSubmitDirective,
    @Inject(FORM_ERRORS) private errors,
    @Inject(PATTERN_ERRORS) private patternerrors,
  ) {
    if (formErrorContainer) {
      this.container = formErrorContainer.vcr;
    } else {
      this.container = vcr;
    }
    if (this.form) {
      this.submit$ = this.form.submit$;
    } else {
      this.submit$ = EMPTY;
    }
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    const obs = this.formDir ? this.formDir.valueChanges : this.formGroupDirective.valueChanges;

    merge(
      this.submit$,
      obs
    ).pipe(
      untilDestroyed(this)
    ).subscribe((v) => {
      const controlErrors = this.formDir ? this.formDir.errors : this.formGroupDirective.errors;
      if (controlErrors) {
        const firstKey = Object.keys(controlErrors)[0];
        const getError = this.errors[firstKey];
        const text = getError(controlErrors).text ?? getError(controlErrors[firstKey]);
        const popOverError = getError(controlErrors).showPopover;
        this.setError(text, popOverError);
        this.ref.instance.labelElementClass = 'errorColor';
      } else if (this.ref) {
        this.setError(null);
        this.ref.instance.labelElementClass = '';
      }
    });
  }

  setError(text: string, popOverError = false): void {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(FormErrorComponent);
      this.ref = this.vcr.createComponent(factory);
    }

    this.ref.instance.text = text;
    this.ref.instance.popOverError = popOverError;
    this.ref.instance.labelElement = this.elementRef.nativeElement.parentElement?.getElementsByTagName('label');
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid);
    const invalidParent = !!(control && control.parent && control.parent.invalid);

    return (invalidCtrl || invalidParent);
  }
}
