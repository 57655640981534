import {
  ActivityStatus,
  ActivityType,
  CertificationDecision,
  CertificationPhase,
  ChoiceCoursePackage,
  ClientExportExtraField,
  CompanyRegion,
  ExportFileType,
  ExportType,
  ExternalCommunicationStatus,
  ForeignDiploma,
  Gender,
  GoStatus,
  IntakeHealthcareLevel,
  InternshipCompanyContactType,
  PaymentBy,
  PreviousEducationLevel,
  RemoteDocType,
  StopReasonType,
  StudyApplicationStatus,
  StudyComponentType,
  StudyLevel,
  StudyModuleType,
  StudyPhase,
  StudyResultType,
  StudyType,
  SurveyIdentifiers,
  TicketCategory,
  TicketPriority,
  TicketStatus,
  TimeRegistrationType,
  UserRole
} from '../../core/models';
import {DropdownData, StudyModuleTypes} from '../../core/models/_template';

export function genderToText(gender: Gender): string {
  switch (gender) {
    case Gender.Male:
      return 'Man';
    case Gender.Female:
      return 'Vrouw';
    default:
      return 'Overig';
  }
}

export function educationToText(education: PreviousEducationLevel): string {
  const str = education.substring(1);
  return education[0].toUpperCase() + str;
}

export interface IconData {
  icon: string;
  color: string;
  name: string;
}

export function studyApplicationStatusToIcon(status: StudyApplicationStatus): IconData {
  switch (status) {
    case StudyApplicationStatus.Concept:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Concept'
      };
    case StudyApplicationStatus.Denied:
      return {
        icon: 'cancel',
        color: '#873C8A',
        name: 'Afgewezen'
      };
    case StudyApplicationStatus.Intake:
      return {
        icon: 'event',
        color: '#4C8C4A',
        name: 'Intake'
      };
    case StudyApplicationStatus.WaitOnClient:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Wacht op reactie'
      };
    case StudyApplicationStatus.Accepted:
      return {
        icon: 'done',
        color: '#4C8C4A',
        name: 'Geaccepteerd'
      };
    default: {
      return {
        icon: 'error',
        color: '#808080',
        name: 'Onbekend'
      };
    }
  }
}

export function intakeGradingToText(grading): string {
  switch (grading) {
    case IntakeHealthcareLevel.BelowAverage:
      return 'A: Weinig zorgervaring';
    case IntakeHealthcareLevel.Average:
      return 'B: Gemiddelde zorgervaring';
    case IntakeHealthcareLevel.Good:
      return 'C: Voldoende zorgervaring';
  }
}

export function certificationPhaseStatusToIcon(status: CertificationPhase): IconData {
  switch (status) {
    case CertificationPhase.Open:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Open'
      };
    case CertificationPhase.CheckReport:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Na te kijken'
      };
    case CertificationPhase.ReportAccepted:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Verslag voldoende'
      };
    case CertificationPhase.AwaitingResponse:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'In afwachting van reactie'
      };
    case CertificationPhase.AwaitingProduct:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Wacht op product'
      };
    case CertificationPhase.Invite:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Uitnodigen'
      };
    case CertificationPhase.Assessment:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Assessment gesprek'
      };
    case CertificationPhase.ExamsCompleted:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Schoolexamens gehaald'
      };
    case CertificationPhase.RetryReport:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Verslag herkansen'
      };
    case CertificationPhase.RetryAssessment:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Gesprek herkansen'
      };
    case CertificationPhase.AssessmentCompleted:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Gesprek voldoende'
      };
    case CertificationPhase.DiplomaCheck:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Nakijken examenbureau'
      };
    case CertificationPhase.DiplomaCheckNegative:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Examenbureau negatief'
      };
    case CertificationPhase.DiplomaGrading:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Beoordelen examencommissie'
      };
    case CertificationPhase.DiplomaRefused:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Negatief diplomabesluit'
      };
    case CertificationPhase.DiplomaGranted:
      return {
        icon: 'queue',
        color: '#8C6E83',
        name: 'Positief diplomabesluit'
      };
    default: {
      return {
        icon: 'error',
        color: '#808080',
        name: 'Onbekend'
      };
    }
  }
}

export const ChoiceCoursePackages: DropdownData[] = [{
  value: ChoiceCoursePackage.Package_1,
  name: 'Pakket 1'
}, {
  value: ChoiceCoursePackage.Package_2,
  name: 'Pakket 2'
}, {
  value: ChoiceCoursePackage.Package_3,
  name: 'Pakket 3'
}, {
  value: ChoiceCoursePackage.Package_4,
  name: 'Pakket 4'
}, {
  value: ChoiceCoursePackage.Package_5,
  name: 'Pakket 5'
}];

export const GoStatuses: DropdownData[] = [{
  value: GoStatus.None,
  name: 'Geen'
}, {
  value: GoStatus.Requested,
  name: 'Aangevraagd'
}, {
  value: GoStatus.Accepted,
  name: 'Geaccepteerd'
}, {
  value: GoStatus.Denied,
  name: 'Afgewezen'
}];

export const StudyPhaseStatuses: DropdownData[] = [{
  value: StudyPhase.Development,
  name: 'Ontwikkelingsfase'
}, {
  value: StudyPhase.Exams,
  name: 'Kwalificerende fase'
}, {
  value: StudyPhase.Certified,
  name: 'Gediplomeerd'
}, {
  value: StudyPhase.Cancelled,
  name: 'Gestopt'
}, {
  value: StudyPhase.Awaiting,
  name: 'Prospect'
}, {
  value: StudyPhase.Migrated,
  name: 'Geïmporteerd'
}];

export const Genders: DropdownData[] = [{
  value: Gender.Male,
  name: 'Man'
}, {
  value: Gender.Female,
  name: 'Vrouw'
}, {
  value: Gender.Other,
  name: 'Non-binair of onbekend'
}];

export const StudyApplicationStatuses: DropdownData[] = [{
  value: StudyApplicationStatus.Concept,
  name: 'Concept'
}, {
  value: StudyApplicationStatus.Open,
  name: 'Open'
}, {
  value: StudyApplicationStatus.Intake,
  name: 'Wachten op intake'
}, {
  value: StudyApplicationStatus.Accepted,
  name: 'Geaccepteerd'
}, {
  value: StudyApplicationStatus.Denied,
  name: 'Afgewezen'
}, {
  value: StudyApplicationStatus.Started,
  name: 'Gestart'
}, {
  value: StudyApplicationStatus.WaitOnClient,
  name: 'Wachten op reactie'
}];

export const EducationLevels: DropdownData[] = [{
  value: PreviousEducationLevel.NONE,
  name: 'Geen'
}, {
  value: PreviousEducationLevel.LBO,
  name: 'LBO'
}, {
  value: PreviousEducationLevel.VBO,
  name: 'VBO'
}, {
  value: PreviousEducationLevel.VMBO,
  name: 'VMBO'
}, {
  value: PreviousEducationLevel.MAVO,
  name: 'MAVO'
}, {
  value: PreviousEducationLevel.MAVO_VBO,
  name: 'MAVO VBO'
}, {
  value: PreviousEducationLevel.HAVO,
  name: 'HAVO'
}, {
  value: PreviousEducationLevel.MULO,
  name: 'MULO'
}, {
  value: PreviousEducationLevel.VWO,
  name: 'VWO'
}, {
  value: PreviousEducationLevel.MBO_1,
  name: 'MBO 1'
}, {
  value: PreviousEducationLevel.MBO_2,
  name: 'MBO 2'
}, {
  value: PreviousEducationLevel.MBO_3,
  name: 'MBO 3'
}, {
  value: PreviousEducationLevel.MBO_4,
  name: 'MBO 4'
}, {
  value: PreviousEducationLevel.HBO,
  name: 'HBO'
}, {
  value: PreviousEducationLevel.WO,
  name: 'WO'
}];

export const PaymentByDropdown: DropdownData[] = [{
  value: PaymentBy.Undetermined,
  name: 'Onbekend'
}, {
  value: PaymentBy.Self,
  name: 'Zelf'
}, {
  value: PaymentBy.Company,
  name: 'Bedrijf'
}];

export const ForeignDiplomaDropdown: DropdownData[] = [{
  value: ForeignDiploma.No,
  name: 'Nee'
}, {
  value: ForeignDiploma.Recognised,
  name: 'Ja - erkend'
}, {
  value: ForeignDiploma.NonRecognised,
  name: 'Ja - niet erkend'
}];

export const StudyLevels: DropdownData[] = [{
  name: 'Cursus',
  value: StudyLevel.Course
}, {
  name: 'Keuzedeel',
  value: StudyLevel.ChoiceCourse
}, {
  name: 'MBO-1',
  value: StudyLevel.MBO_1
}, {
  name: 'MBO-2',
  value: StudyLevel.MBO_2
}, {
  name: 'MBO-3',
  value: StudyLevel.MBO_3
}, {
  name: 'MBO-4',
  value: StudyLevel.MBO_4
}, {
  name: 'HBO-5',
  value: StudyLevel.HBO_5
}];

export const EducationType: DropdownData[] = [{
  name: 'Cursus',
  value: StudyType.Course
}, {
  name: 'Opleiding',
  value: StudyType.Education
}/*, {
  name: 'Keuzedeel',
  value: StudyType.ChoiceCourse
}*/];

export const StudyModuleTypeDropdown: DropdownData[] = [{
  name: 'Algemene module',
  value: StudyModuleType.Generic
}, {
  name: 'Toetsmodule',
  value: StudyModuleType.TestModule
}, {
  name: 'Examenmodule',
  value: StudyModuleType.ExamModule
}, {
  name: 'Stagemodule',
  value: StudyModuleType.InternshipModule
}, {
  name: 'Cursusmodule',
  value: StudyModuleType.CourseModule
}, {
  name: 'Skillsmodule',
  value: StudyModuleType.SkillModule
}, {
  name: 'VirtualSkillsmodule',
  value: StudyModuleType.VirtualSkillModule
}, {
  name: 'Keuzedeelmodule',
  value: StudyModuleType.ChoiceCourseModule
}];

// FIXME: See above, why is this duplicated? Seems to be used for display, one is StudyModuleType, the other StudyModuleTypeS, silly.
export const StudyModuleTypesDropdown: DropdownData[] = [{
  name: 'Algemeen',
  value: StudyModuleTypes.Generic
}, {
  name: 'Toetsen',
  value: StudyModuleTypes.TestModule
}, {
  name: 'Examens',
  value: StudyModuleTypes.ExamModule
}, {
  name: 'Stages',
  value: StudyModuleTypes.InternshipModule
}, {
  name: 'Cursussen',
  value: StudyModuleTypes.CourseModule
}, {
  name: 'Keuzedelen',
  value: StudyModuleTypes.ChoiceCourseModule
}, {
  name: 'Skills',
  value: StudyModuleTypes.SkillModule
}, {
  name: 'Virtuele skills',
  value: StudyModuleTypes.VirtualSkillModule
}];

export const RemoteDocTypeDropdown: DropdownData[] = [{
  name: 'Diploma',
  value: RemoteDocType.Diploma
}, {
  name: 'Foto/scan/beeld',
  value: RemoteDocType.Image
}, {
  name: 'OOK',
  value: RemoteDocType.OOK
}, {
  name: 'POK',
  value: RemoteDocType.POK
}, {
  name: 'Document',
  value: RemoteDocType.Text
}, {
  name: 'Video',
  value: RemoteDocType.Video
}, {
  name: 'Certificaat',
  value: RemoteDocType.Certificate
}];

export const CertificationPhaseDropdown: DropdownData[] = [
  {
    value: CertificationPhase.None,
    name: 'Onbekend'
  },
  {
    value: CertificationPhase.Open,
    name: 'Open'
  },
  {
    value: CertificationPhase.CheckReport,
    name: 'Na te kijken'
  },
  {
    value: CertificationPhase.AwaitingResponse,
    name: 'In afwachting van reactie'
  },
  {
    value: CertificationPhase.AwaitingProduct,
    name: 'Wacht op product'
  },
  {
    value: CertificationPhase.Invite,
    name: 'Uitnodigen'
  },
  {
    value: CertificationPhase.ReportAccepted,
    name: 'Verslag voldoende'
  },
  {
    value: CertificationPhase.Assessment,
    name: 'Assessment gesprek'
  },
  {
    value: CertificationPhase.ExamsCompleted,
    name: 'Schoolexamens gehaald'
  },
  {
    value: CertificationPhase.RetryReport,
    name: 'Verslag herkansen'
  },
  {
    value: CertificationPhase.RetryAssessment,
    name: 'Gesprek herkansen'
  },
  {
    value: CertificationPhase.AssessmentCompleted,
    name: 'Gesprek voldoende'
  },
  {
    value: CertificationPhase.DiplomaCheck,
    name: 'Nakijken examenbureau'
  },
  {
    value: CertificationPhase.DiplomaCheckNegative,
    name: 'Examenbureau negatief'
  },
  {
    value: CertificationPhase.DiplomaGrading,
    name: 'Beoordelen examencommissie'
  },
  {
    value: CertificationPhase.DiplomaRefused,
    name: 'Negatief diplomabesluit'
  },
  {
    value: CertificationPhase.DiplomaGranted,
    name: 'Positief diplomabesluit'
  }
];

export const UserRoleDropdown: DropdownData[] = [{
  name: 'Assessor',
  value: UserRole.Assessor,
}, {
  name: 'Studiecoach',
  value: UserRole.StudyCoach,
}, {
  name: 'Examenbureau',
  value: UserRole.ExamOffice,
}];

export const GradeSufficientInsufficientDropdown: DropdownData[] = [{
  name: 'Voldoende',
  value: 10,
}, {
  name: 'Onvoldoende',
  value: 1,
}];

export const CertificationDecisionDropdown: DropdownData[] = [{
  name: 'Nog niet beoordeeld',
  value: CertificationDecision.Undecided
}, {
  name: 'Positief diplomabesluit',
  value: CertificationDecision.Accepted
}, {
  name: 'Negatief diplomabesluit',
  value: CertificationDecision.Denied
}];

export const StudyComponentTypeDropdown: DropdownData[] = [{
  value: StudyComponentType.Test,
  name: 'Toets'
}, {
  value: StudyComponentType.Internship,
  name: 'Stagedeel'
}, {
  value: StudyComponentType.Exam,
  name: 'Examen (oud)'
}, {
  value: StudyComponentType.Diploma,
  name: 'Diplomabesluit'
}, {
  value: StudyComponentType.Generic,
  name: 'Algemeen'
}, {
  value: StudyComponentType.Go,
  name: 'Go'
}, {
  value: StudyComponentType.Assessment,
  name: 'Assessment'
}, {
  value: StudyComponentType.Migrated,
  name: 'Geïmporteerd'
}, {
    value: StudyComponentType.PracticalExam,
    name: 'Praktijkexamendeel'
  }, {
    value: StudyComponentType.TheoreticalExam,
    name: 'Theorie-examendeel'
  }, {
    value: StudyComponentType.ChoiceExam,
    name: 'Keuzeonderdeel-examen'
  }, {
  value: StudyComponentType.Course,
  name: 'Cursusdeel'
}, {
  value: StudyComponentType.Choice,
  name: 'Keuzeonderdeel'
}, {
  value: StudyComponentType.Quiz,
  name: 'Quiz'
}, {
  value: StudyComponentType.Skill,
  name: 'Skill'
}, {
  value: StudyComponentType.CareupProtocol,
  name: 'Careup-protocol'
}];

export const StudyResultTypeDropdown: DropdownData[] = [
  {
    value: StudyResultType.Number,
    name: 'Cijfer'
  },
  // {
  //   value: StudyResultType.Grade,
  //   name: 'Beoordeling'
  // },
  // {
  //   value: StudyResultType.NoShow,
  //   name: 'Niet op komen dagen'
  // },
  {
    value: StudyResultType.ThreePointScale,
    name: 'Driepuntenschaal'
  },
  {
    value: StudyResultType.TwoPointScale,
    name: 'Behaald/niet behaald'
  },
  {
    value: StudyResultType.TwoPointScale_B,
    name: 'Voldaan/niet voldaan'
  },
  // {
  //   value: StudyResultType.Exempt,
  //   name: 'Vrijstelling'
  // },
];

export const ExternalCommunicationStatusDropdown: DropdownData[] = [{
  value: ExternalCommunicationStatus.Created,
  name: 'Aangemaakt'
}, {
  value: ExternalCommunicationStatus.Sent,
  name: 'Verstuurd'
}, {
  value: ExternalCommunicationStatus.Delivered,
  name: 'Ontvangen'
}, {
  value: ExternalCommunicationStatus.Failed,
  name: 'Foutmelding'
}, {
  value: ExternalCommunicationStatus.Rejected,
  name: 'Geweigerd'
}, {
  value: ExternalCommunicationStatus.Cancelled,
  name: 'Geannuleerd'
}];

export const TicketCategoryDropdown: DropdownData[] = [{
  value: TicketCategory.Generic,
  name: 'Algemeen'
}, {
  value: TicketCategory.StartStudy,
  name: 'Interesse opleiding'
}, {
  value: TicketCategory.GoRequest,
  name: 'GO-gesprek'
}, {
  value: TicketCategory.InternshipRequest,
  name: 'Stage aanvraag'
}, {
  value: TicketCategory.Assignment,
  name: 'Opdrachten'
}, {
  value: TicketCategory.Skill,
  name: 'Skills'
}];

export const TicketStatusDropdown: DropdownData[] = [{
  value: TicketStatus.Open,
  name: 'Open'
}, {
  value: TicketStatus.Processing,
  name: 'In behandeling'
}, {
  value: TicketStatus.Done,
  name: 'Afgerond'
}, {
  value: TicketStatus.Archived,
  name: 'Gearchiveerd'
}];

export const TicketPriorityDropdown: DropdownData[] = [{
  value: TicketPriority.None,
  name: 'Geen'
}, {
  value: TicketPriority.Low,
  name: 'Laag'
}, {
  value: TicketPriority.Normal,
  name: 'Normaal'
}, {
  value: TicketPriority.High,
  name: 'Hoog'
}, {
  value: TicketPriority.Urgent,
  name: 'Urgent'
}];

export const InternshipCompanyStatusDropdown: DropdownData[] =  [
  {
    value: true,
    name: 'Actief'
  },
  {
    value: false,
    name: 'Non-actief'
  }
];

export const TimeRegistrationTypeDropdown: DropdownData[] = [{
  value: TimeRegistrationType.BOT,
  name: 'Bot-uren'
}, {
  value: TimeRegistrationType.BPV,
  name: 'Bpv-uren'
}, /*{
  value: TimeRegistrationType.Generic,
  name: 'Generieke uren'
}*/];

export const CompanyRegionDropdown: DropdownData[] = [{
  value: CompanyRegion.A,
  name: 'A'
}, {
  value: CompanyRegion.B,
  name: 'B'
}, {
  value: CompanyRegion.C,
  name: 'C'
}, {
  value: CompanyRegion.D,
  name: 'D'
}, {
  value: CompanyRegion.E,
  name: 'E'
}, {
  value: CompanyRegion.F,
  name: 'F'
}, {
  value: CompanyRegion.G,
  name: 'G'
}, {
  value: CompanyRegion.H,
  name: 'H'
}, {
  value: CompanyRegion.I,
  name: 'I'
}];

export const InternshipCompanyContactTypeDropdown = [
  {value: InternshipCompanyContactType.ContactPerson, name: 'Contactpersoon', id: 6},
  {value: InternshipCompanyContactType.Assessor, name: 'Beoordelaar', id: 4},
  {value: InternshipCompanyContactType.Mentor, name: 'Begeleider', id: 3},
  {value: InternshipCompanyContactType.Other, name: 'Overige', id: 7},
];

export const ExportTypeDropdown: DropdownData[] = [
  {value: ExportType.Legacy, name: 'Oude export'},
  {value: ExportType.New, name: 'Nieuwe export'},
];

export const ExportFileTypeDropdown: DropdownData[] = [
  {value: ExportFileType.Excel, name: 'Excel'},
  {value: ExportFileType.CSV, name: 'CSV'},
];

export const ClientExtraFieldDropdown: DropdownData[] = [
  {value: ClientExportExtraField.Study, name: 'Opleiding'},
  {value: ClientExportExtraField.Class, name: 'Klas'},
  {value: ClientExportExtraField.Cohort, name: 'Cohort'},
  {value: ClientExportExtraField.Internship, name: 'Stage'},
  {value: ClientExportExtraField.StartDate, name: 'Startdatum'},
  {value: ClientExportExtraField.EndDate, name: 'Einddatum'},
  {value: ClientExportExtraField.Status, name: 'Status'},
  {value: ClientExportExtraField.Result, name: 'Resultaten (beta)'},
  {value: ClientExportExtraField.HealthcareLevel, name: 'Zorgervaring en Nederlands aandachtspunt ja/nee (beta)'},
];

export const InternshipTypeDropdown: DropdownData[] = [
  {value: '', name: 'Examinerend'},
  {value: '', name: 'Kwalificerend'},
  {value: '', name: 'Ontwikkelend'},
];

// TODO: move role to entity, so surveys can be retrieved dynamically.
export const SurveyIdentifierDropdown: DropdownData[] = [
  {value: SurveyIdentifiers.IntakeSurveyOld, name: 'Na de intake (oud)', role: 'student'},
  {value: SurveyIdentifiers.BpvVisitSurveyOld, name: 'BPV enquête (oud)', role: 'bpv'},
  {value: SurveyIdentifiers.IntakeSurvey, name: 'Na de intake', role: 'student'},
  {value: SurveyIdentifiers.HalfwaySurvey, name: 'Tijdens les 7', role: 'student'},
  {value: SurveyIdentifiers.AfterTwoMonthsStudentSurvey, name: 'Circa twee maanden na de POK (student)', role: 'student'},
  {value: SurveyIdentifiers.AfterTwoMonthsCompanySurvey, name: 'Circa twee maanden na de POK (bedrijf)', role: 'bpv'},
  {value: SurveyIdentifiers.BpvVisitSurvey, name: 'BPV enquête', role: 'bpv'},
  {value: SurveyIdentifiers.AssessmentSurvey, name: 'Bij aanvraag assessment', role: 'student'},
  {value: SurveyIdentifiers.CertificateSurvey, name: 'Na uitreiking diploma', role: 'student'},
];

export const StudyTrackStopReasonDropdown: DropdownData[] = [
  {value: StopReasonType.PersonalFactorsWithNoInfluence, name: 'Persoonsgebonden factoren waar de instelling in principe absoluut geen invloed op kan uitoefenen'},
  {value: StopReasonType.PersonalFactorsWithInfluence, name: 'Persoonsgebonden factoren waar de instelling in principe niets kan doen aan de oorzaken'},
  {value: StopReasonType.InstitutionalFactors, name: 'Instellingsgebonden factoren'},
  {value: StopReasonType.StudyAndCareerRelatedFactors, name: 'Studie- en beroepskeuzegebonden factoren'},
  {value: StopReasonType.LaborAndEnvironmentalFactors, name: 'Arbeidsmarkt- en (externe) omgevingsfactoren'},
  {value: StopReasonType.WithoutDiploma, name: 'Zonder diploma, maar wel succesvol / afgesproken resultaat behaald'},
  {value: StopReasonType.Unknown, name: 'Onbekend'},
  {value: StopReasonType.FactorsRelatedToCrebo, name: 'Geen uitval. Factoren die te maken hebben met de crebo-toekenning van experimentele opleidingen'},
  {value: StopReasonType.PaymentObligationNotMet, name: 'Niet voldaan aan betalingsverplichting'},
  {value: StopReasonType.Certified, name: 'Gediplomeerd'}
];

export function enumValueToName(arr: any[], value: string): string {
  const fnd = arr.find(val => val.value === value);
  if (fnd) {
    return fnd.name;
  }

  return 'Niet gevonden';
}

export const ActivityTypeDropdown: DropdownData[] = [
  { value: ActivityType.SkillsLab, name: 'Skillslab'},
  { value: ActivityType.Course, name: 'Cursus'},
  { value: ActivityType.Lesson, name: 'Les'},
];

export const ActivityStatusDropdown: DropdownData[] = [
  { value: ActivityStatus.Open, name: 'Open/bezig'},
  { value: ActivityStatus.Submitted, name: 'Afgerond'},
  { value: ActivityStatus.Closed, name: 'Afgerond'},
];

export const ResultLocationDropdown: DropdownData[] = [
  { value: 'at_bpv', name: 'Stage/BPV'},
  { value: 'at_skillslab', name: 'School/skillslab'},
];

// export function studyComponentTypeToText(type: string): string {
//   switch (type) {
//     case StudyComponentType.Test:
//       return 'Toets';
//     case StudyComponentType.Test:
//       return 'Toets';
//     case StudyComponentType.Test:
//       return 'Toets';
//     case StudyComponentType.Test:
//       return 'Toets';
//     case StudyComponentType.Test:
//       return 'Toets';
//     case StudyComponentType.Test:
//       return 'Toets';
//     case StudyComponentType.Test:
//       return 'Toets';
//   }
// }
