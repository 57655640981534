import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from "moment";
moment.locale('nl');
import {CreateStudy, DocUpload, LessonDocUpload, RemoteDocument, Study} from "../../../models";
import {environment} from "../../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RemoteDocumentService {

  constructor(private http: HttpClient) {

  }

  get(id: number): Observable<RemoteDocument> {
    return this.http.get<RemoteDocument>(`${environment.apiUrl}/remote-document/${id}`);
  }

  uploadToClient(clientId: number, doc: DocUpload): Observable<any> {
    const formData = this.parseFormData(doc);
    return this.http.post<FormData>(`${environment.apiUrl}/client/${clientId}/attachment`, formData);
  }

  uploadToEntity(entity: string, entityId: number, doc: DocUpload): Observable<any> {
    const formData = this.parseFormData(doc);
    return this.http.post<FormData>(`${environment.apiUrl}/${entity}/${entityId}/attachment`, formData);
  }

  uploadToStudyApplication(applicationId: number, doc: DocUpload): Observable<any> {
    const formData = this.parseFormData(doc);
    return this.http.post<FormData>(`${environment.apiUrl}/study-application/${applicationId}/attachment`, formData);
  }

  uploadToStudyModuleSpecification(specId: number, doc: DocUpload): Observable<any> {
    const formData = this.parseFormData(doc);
    return this.http.post<FormData>(`${environment.apiUrl}/study-module-specification/${specId}/attachment`, formData);
  }

  uploadToStudyModuleLesson(lessonId: number, doc: LessonDocUpload): Observable<any> {
    const formData = this.parseFormData(doc);
    formData.append('materialType', doc.materialType);
    return this.http.post<FormData>(`${environment.apiUrl}/study-module-lesson/${lessonId}/attachment`, formData);
  }

  downloadFile(id: number): any {
    return this.http.get(`${environment.apiUrl}/remote-document/${id}/view`, {responseType: 'blob', observe: 'response'}).pipe(map(res => {
      return new Blob([res.body], { type: res.headers.get('Content-Type') });
    }));
  }

  delete(id: number): any {
    return this.http.delete(`${environment.apiUrl}/remote-document/${id}`);
  }

  getAccessLink(uri: string): Observable<string> {
    return this.http.get(`${environment.apiUrl}/remote-document/link?uri=${uri}`, {responseType: 'text'});
  }

  importRemindoResults(doc: DocUpload): any {
    const formData = this.parseFormData(doc);
    return this.http.post<FormData>(`${environment.apiUrl}/study-result/remindo-import-results`, formData);
  }

  private parseFormData(doc: DocUpload): FormData {
    const formData = new FormData();
    formData.append('file', doc.file, doc.name);
    formData.append('name', doc.name);
    formData.append('type', doc.type);
    return formData;
  }

  public uploadTimeRegistrationDoc(registrationId: number, doc: DocUpload): Observable<any> {
    const formData = this.parseFormData(doc);
    return this.http.post<FormData>(`${environment.apiUrl}/time-registration/${registrationId}/attachment`, formData);
  }
}
