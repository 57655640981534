<div class="dialog">
  <div class="dialog__wrapper">
    <div class="dialog__title">
      <div class="mb-0 me-3">{{title}}</div>

      <button
        [autofocus]="false"
        mat-button
        (click)="closeAction ? closeAction.emit() : dialogRef.close()"
        class="close-button" align="end"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <mat-dialog-content
      class="dialog__content"
      [class.dialog__content--noPadding]="noContentPadding"
      [class.dialog__content--noMaxHeight]="noMaxHeight"
    >
      <ng-content></ng-content>
    </mat-dialog-content>

    <mat-dialog-actions class="dialog__actions" align="end">
      <ng-content select="[dialog-actions]"></ng-content>
    </mat-dialog-actions>
  </div>
</div>

