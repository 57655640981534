import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssignmentSubmissionService } from '../../../core/services/medivus/assignment-submission/assignment-submission.service';
import {
  AssignmentSubmission,
  CreateStudyResult,
  StudyResultType
} from '../../../core/models';
import { StudyResultService } from '../../../core/services/medivus/study-result/study-result.service';

@Component({
  selector: 'app-handed-in-documents-dialog',
  templateUrl: './handed-in-documents-dialog.component.html',
  styleUrls: ['./handed-in-documents-dialog.component.scss']
})
export class HandedInDocumentsDialogComponent implements OnInit {
  static panelClass = 'handedInDocumentsDialog';
  selectedResult = '';
  resultsFormGroup: FormGroup;
  submission: AssignmentSubmission;

  constructor(public dialogRef: MatDialogRef<HandedInDocumentsDialogComponent>,
              public assignmentSubmissionService: AssignmentSubmissionService,
              public studyResultService: StudyResultService,
              public formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.resultsFormGroup = this.formBuilder.group({
      result: [null, [Validators.required,
        Validators.pattern("^(\\d+(?:[\\.\\,]\\d{1})?)$"),
        Validators.min(0), Validators.max(10)]
      ],
      remarks: ['']
    });
  }

  async ngOnInit() {
    await this.getSubmission();
  }

  async getSubmission(): Promise<void> {
    this.submission = await this.assignmentSubmissionService.get(this.data.id).toPromise();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  // TODO refactor this to backend.
  async save() {
    const res = this.resultsFormGroup.value;

    const result = new CreateStudyResult();
    result.clientId = Number(this.submission.studyProfileComponent.studyProfile.clientStudyTrack.clientId);
    result.result = Number(res.result.replace(',', '.'));
    result.clientStudyTrackId = Number(this.submission.studyProfileComponent.studyProfile.clientStudyTrack.id);
    result.studyComponentId = Number(this.submission.studyProfileComponent.studySpecificationComponentMeta.studyComponentId);
    result.date = new Date();
    result.resultType = StudyResultType.Number;
    result.remarks = res.remarks;

    try {
      const studyResult = await this.studyResultService.create(result).toPromise();
      await this.assignmentSubmissionService.update(this.submission.id, {
        studyResultId: studyResult.id,
        graded: true
      }).toPromise();
    } catch(e) {
      console.log(e);
    }

    this.dialogRef.close(true);
  }
}
