import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
moment.locale('nl');
import {
  AssignmentSubmission, CreateAssignmentSubmission,
  UpdateAssignmentSubmission,
} from '../../../models';
import { environment } from '../../../../../environments/environment';
import { plainToClass } from 'class-transformer';
import {BaseRestAPIService} from "../../base-rest-api/base-rest-api.service";

@Injectable({
  providedIn: 'root'
})
export class AssignmentSubmissionService extends BaseRestAPIService<AssignmentSubmission, CreateAssignmentSubmission, UpdateAssignmentSubmission> {
  constructor(http: HttpClient) {
    super(http, `${environment.apiUrl}/assignment-submission`, input => plainToClass(AssignmentSubmission, input));
  }
}

