import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogData} from '../../../../core/models/_template';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  static panelClass = 'confirmDialog';
  static width = '300px';
  color;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
  }

  public close() {
    this.dialogRef.close(false);
  }

  public confirm() {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
    if (!this.data.confirmText) {
      if (this.data.deletable) {
        this.data.confirmText = 'Verwijderen';
        this.color = '#FF0000';
      } else {
        this.data.confirmText = 'Bevestigen';
        this.color = '#66ADAF';
      }
    }

    if (!this.data.cancelText) {
      this.data.cancelText = 'Annuleren';
    }
  }
}
