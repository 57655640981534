<div class="inputBundler">
  <button mat-button *ngIf="text === 'Opslaan' && hiddenButton.id === 'hiddenCheckRequestButton'" (click)="cancelForm()">Annuleren</button>
  <button mat-button class="primaryButton" [class.spinnerButton]="busyVariable" (click)="hiddenButton.click()">
    <ng-container *ngIf="!busyVariable; else formLoader">
      {{text}}
    </ng-container>
    <ng-template #formLoader>
      <mat-spinner [diameter]="20"></mat-spinner>
    </ng-template>
  </button>
</div>
