import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {SortDirection} from "@angular/material/sort/sort-direction";
import {PaginatedResult} from "../../models";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

moment.locale('nl');

export class APIFilterOption<T> {
  //key: (keyof T & string); // FIXME unfortunately we cant use custom filters that dont exist in the object if we do this...
  key: string;
  value: string | number | string[] | number[] | object;
}

export class APISortOptions<T> {
  //active: (keyof T & string); // TODO: interoperability with mat-angular Sort class would be great, also for type checking.
  active: string;
  direction: SortDirection;
}

export abstract class BaseRestAPIService<ReadDTO extends { id }, CreateDTO, UpdateDTO> {
  protected entity: string;
  protected apiUrl: string;

  // TODO: Set the apiUrl here, instead of in every service
  // TODO: Just let each service set it's own entity string
  protected constructor(protected http: HttpClient,
                        protected url: string,
                        protected mapper: (input: any) => ReadDTO) {
    this.apiUrl = url;
  }

  get(id: number): Observable<ReadDTO> {
    return this.http.get<ReadDTO>(
      `${this.apiUrl}/${id}`
    );
  }

  getAll(pageIndex = 1,
         pageSize = 10,
         search: string = null,
         filter: APIFilterOption<ReadDTO>[] = [],
         sort: APISortOptions<ReadDTO> = {active: 'id', direction: 'asc'}): Observable<PaginatedResult<ReadDTO>> {

    const filterOptions = {};
    for (const item of filter) {
      if (item.value !== null && item.value !== '' || (item.value !== null && Array.isArray(item.value) && item.value.length > 0)) {
        filterOptions[item.key as string] = item.value;
      }
    }

    if (search && search !== '') {
      filterOptions['search'] = encodeURIComponent(search);
    }

    const sortParam = {};
    if (sort) {
      sortParam[sort.active as string] = sort.direction;
    }

    return this.http
      .get<PaginatedResult<ReadDTO>>(
        `${this.apiUrl}?page=${pageIndex}&limit=${pageSize}
            &filter=${JSON.stringify(filterOptions)}&sort=${JSON.stringify(sortParam)}`
      )
      .pipe(
        map(response => {
          response.items = response.items.map(plain =>
            this.mapper(plain)
          );
          return response;
        })
      );
  }

  create(dto: CreateDTO): Observable<ReadDTO> {
    return this.http.post<ReadDTO>(
      this.apiUrl,
      dto
    );
  }

  update(id: number, dto: UpdateDTO) {
    return this.http.patch<ReadDTO>(
      `${this.apiUrl}/${id}`,
      dto
    );
  }

  delete(id: number) {
    return this.http.delete<ReadDTO>(
      `${this.apiUrl}/${id}`
    );
  }
}

