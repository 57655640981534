<ng-container *ngIf="!popOverError">
  <mat-error [class.hide]="_hide">{{_text}}</mat-error>
<!--  <fa-icon class="errorIcon" [class.hide]="_hide" [icon]="faExclamationTriangle"></fa-icon>-->
  <mat-icon class="errorIcon" [class.hide]="_hide">warning_amber</mat-icon>
</ng-container>

<!--<ng-template #popoverError>-->
<!--  <mat-icon>error</mat-icon>-->
<!--  <mat-icon class="errorIcon popOver"-->
<!--           [class.hide]="_hide"-->
<!--           [mdePopoverTriggerFor]="appPopover"-->
<!--           mdePopoverTriggerOn="hover">error</mat-icon>-->

<!--  <mde-popover-->
<!--    #appPopover="mdePopover"-->
<!--    [mdePopoverOverlapTrigger]="false">-->
<!--    <mat-card style="max-width: 40em" class="defaultToolTip">-->
<!--      <mat-card-content>-->
<!--        {{_text}}-->
<!--      </mat-card-content>-->
<!--    </mat-card>-->
<!--  </mde-popover>-->
<!--</ng-template>-->
