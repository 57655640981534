import {Directive, ElementRef} from '@angular/core';
import {fromEvent} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

@Directive({
  selector: 'form'
})
export class FormErrorSubmitDirective {
  submit$ = fromEvent(this.element, 'submit').pipe(shareReplay(1));

  constructor(private host: ElementRef<HTMLFormElement>) { }

  get element(): HTMLFormElement {
    return this.host.nativeElement;
  }
}
